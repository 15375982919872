import React from 'react';
import Layout from 'layouts/Layout';
import Container from 'layouts/Container';
import SEO from 'components/seo';
import styled from 'styled-components';
import { Colors } from 'styles';
import { useTranslation } from 'react-i18next';

const ErrorContent = styled.div`
  min-height: 60vh;
  margin: 2rem 0;
  background-color: ${Colors.bg_lite};
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .errorTitle {
    font-size: 4rem;
    color: ${Colors.gdmc_orange};
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
  }
`;

const Error404Page = ({ location }) => {
  const { i18n, t } = useTranslation();
  return (
    <Layout location={location}>
      <SEO lang={i18n.language} title={t('error_404')} />
      <Container>
        <ErrorContent>
          <h1 className="errorTitle">404</h1>
          <p>{t('page_not_found')}</p>
        </ErrorContent>
      </Container>
    </Layout>
  );
};

export default Error404Page;
